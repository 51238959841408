export default class StickyPopUp {
    constructor(storage) {
        this.storage = storage;
        this.holder = document.getElementById('sticky-pop-up');
        this.closeBtn = document.getElementById('sticky-pop-up--close');
        this.showState = false;

        if(!this.holder) {
            return false;
        }

        if(this.checkCache()) {
            this.show();
        }

        this.bindEvents();
    }

    close() {
        this.animate();
        this.showState = false;
        this.cache();
    };

    show() {
        this.showState = true;
        this.holder.classList.add('promo-is-open');
    }

    cache() {
        this.storage.setItem('stickyPopUp', true);
    }

    checkCache() {
        return !this.storage.getItem('stickyPopUp');
    };

    animate() {
        // this.holder.style.opacity = 0;
        // setTimeout(() => {
            this.holder.classList.remove('promo-is-open');
        // }, 400)
    }

    bindEvents() {
        document.body.addEventListener('click', e => {
            if (e.target.classList.contains(`sticky-pop-up-hide`)) {
                this.close();
            }
        });
    }
}
