import Storage from './components/session.js'
import StickyPopUp from './components/sticky-popup.js'

export default class App {
    constructor() {
      this.storage = new Storage()
      this.initComponents()
      this.initPages()
    }
  
    initComponents() {
      new StickyPopUp(this.storage)
    }
  
    initPages() {
    }
}