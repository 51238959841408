// Import our custom CSS
import '../scss/styles.scss';

// Import vendor scripts
// import Swup from 'swup';
// import SwupScriptsPlugin from '@swup/scripts-plugin';
import { createPopper } from '@popperjs/core';
import { Collapse, Dropdown, Tab, Tooltip } from 'bootstrap';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Swiper from 'swiper';
import { Autoplay, EffectFade, FreeMode, Mousewheel, Navigation, Pagination, Parallax, Scrollbar } from 'swiper/modules';
import VenoBox from 'venobox';
import Marquee3k from 'marquee3000';
import mixitup from 'mixitup';
import bodymovin from 'lottie-web';
import App from './app';

async function getSearchContent() {
    const url = '/wp-admin/admin-ajax.php'
    const formData = new FormData();
    formData.append('action', 'get_search_modal');
    const options = {
        method: 'POST',
        body: formData
    }
  
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
}

function runScripts() {

    new App()
    gsap.registerPlugin(ScrollTrigger); 
    // Remove 'no-scroll' class from body on each page transition
    document.body.classList.remove("no-scroll");

    // Venobox modals
    new VenoBox({
        selector: '.venobox'
    });

    // Show/hide header on scroll
    const header = document.querySelector(".site-header");
    let lastScroll = 0;

    const throttle = (func, time = 100) => {
    let lastTime = 0;
    return () => {
        const now = new Date();
        if (now - lastTime >= time) {
            func();
            time = now;
        }
    };
    };

    const validateHeader = () => {
        const windowY = window.scrollY;
        const headerHeight = header.offsetHeight;
        if (windowY > headerHeight * 2) {
            document.body.classList.add("site-header-fixed");
            if (windowY > (window.innerHeight / 2)) {
                document.body.classList.add("site-header-can-animate");
                if (windowY < lastScroll) {
                    document.body.classList.add("site-header-scroll-up");
                } else {
                    document.body.classList.remove("site-header-scroll-up");
                }
            } else {
                document.body.classList.remove("site-header-scroll-up");
            }
        } else {
            document.body.classList.remove("site-header-fixed", "site-header-can-animate");
        }
        lastScroll = windowY;
    };

    window.addEventListener("scroll", throttle(validateHeader, 100));

    // Toggle site menu
    document.querySelectorAll('.site-menu-toggle').forEach(toggle => {
        toggle.addEventListener('click', () => {
            document.querySelector('.site-menu').classList.toggle('site-menu-is-open');
            document.body.classList.toggle('no-scroll');
        })
    })

    // Workaround for responsive menu for anchors
    document.querySelectorAll('.close-responsive-menu').forEach(toggle => {
        toggle.addEventListener('click', () => {
            document.querySelector('.site-menu').classList.remove('site-menu-is-open');
            document.body.classList.remove('no-scroll');
        })
    })

    window.addEventListener('resize', () => {
        if (document.querySelector('.site-menu').classList.contains('site-menu-is-open')) {
            document.querySelector('.site-menu').classList.remove('site-menu-is-open');
            document.body.classList.remove('no-scroll');
        }
    })

    // Featured insights carousel
    const featuredInsights = new Swiper('.featured-insights-carousel', {
        modules: [EffectFade, Navigation, Pagination, Parallax],
        speed: 600,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            prevEl: '.featured-insights-prev',
            nextEl: '.featured-insights-next',
        },
        pagination: {
            el: '.featured-insights-pagination',
        },
        parallax: true,
    });

    // Nav carousels
    const navCarousels = document.querySelectorAll('.nav-carousel');
    navCarousels.forEach(carousel => {
        const carouselPrev = carousel.querySelector('.nav-carousel-prev');
        const carouselNext = carousel.querySelector('.nav-carousel-next');

        const navCarousel = new Swiper(carousel, {
            modules: [FreeMode, Navigation],
            slidesPerView: 'auto',
            centerInsufficientSlides: true,
            // slideToClickedSlide: true,
            enabled: true,
            // breakpoints: {
            //     992: {
            //         enabled: false,
            //     },
            // },
            freeMode: {
                enabled: true,
            },
            navigation: {
                prevEl: carouselPrev,
                nextEl: carouselNext,
            },
        });
    });

    // Nav carousels switch to sidebar for lg breakpoint
    const navSidebarCarousels = document.querySelectorAll('.nav-carousel-lg-sidebar');
    navSidebarCarousels.forEach(carousel => {
        const carouselPrev = carousel.querySelector('.nav-carousel-prev');
        const carouselNext = carousel.querySelector('.nav-carousel-next');

        const navSidebarCarousel = new Swiper(carousel, {
            modules: [FreeMode, Navigation],
            slidesPerView: 'auto',
            centerInsufficientSlides: true,
            enabled: true,
            breakpoints: {
                992: {
                    enabled: false,
                },
            },
            freeMode: {
                enabled: true,
            },
            navigation: {
                prevEl: carouselPrev,
                nextEl: carouselNext,
            }
        });
    });

    // Packages carousel
    const packagesCarousel = new Swiper('.packages-carousel', {
        modules: [Navigation, Scrollbar],
        slidesPerView: 'auto',
        navigation: {
            prevEl: '.packages-carousel-prev',
            nextEl: '.packages-carousel-next',
        },
        scrollbar: {
            el: '.packages-carousel-scrollbar',
        },
    });

    // Timeline carousel
    const timelineCarousel = new Swiper('.timeline-carousel', {
        modules: [Navigation, Scrollbar],
        slidesPerView: 'auto',
        navigation: {
            prevEl: '.timeline-carousel-prev',
            nextEl: '.timeline-carousel-next',
        },
        scrollbar: {
            el: '.timeline-carousel-scrollbar',
        },
    });

    

    // Modals
    const modals = document.querySelectorAll('.modal');
    modals.forEach(modal => {

        // Toggle modal open/close
        const id = modal.id;
        const modalTriggers = document.querySelectorAll(`.modal-toggle[href="#${id}"]`);
        const modalClose = modal.querySelector('.modal-close');
        const modalOverlay = modal.querySelector('.modal-overlay');
        modalTriggers.forEach(trigger => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();
                modal.classList.add('modal-is-open');
                document.body.classList.add('no-scroll');
            })
        })
        modalClose.addEventListener('click', () => {
            modal.classList.remove('modal-is-open');
            document.body.classList.remove('no-scroll');
        })
        modalOverlay.addEventListener('click', () => {
            modal.classList.remove('modal-is-open');
            document.body.classList.remove('no-scroll');
        })
    })

    // Search modal
    const searchModal = document.querySelector('.search-modal');
    const searchModalTriggers = document.querySelectorAll('.search-modal-toggle');
    const searchModalClose = searchModal.querySelector('.search-modal-close');
    const searchModalContent = document.getElementById('search-modal-content');
    searchModalTriggers.forEach(trigger => {
        trigger.addEventListener('click', async () => {
            const htmlContent = await getSearchContent();
            const parser = new DOMParser();
	        const doc = parser.parseFromString(htmlContent.data, 'text/html');
            searchModalContent.innerHTML = doc.body.innerHTML;
            searchModal.classList.add('search-modal-is-open');
            document.body.classList.add('no-scroll');
            setTimeout(function(){
                const searchInput = document.querySelector('.tender-search-form input')
                searchInput.focus()
            }, 200)
            
        })
    })
    searchModalClose.addEventListener('click', () => {
        searchModal.classList.remove('search-modal-is-open');

        if (!document.querySelector('.site-menu').classList.contains('site-menu-is-open')) {
            document.body.classList.remove('no-scroll');
        }
    })

    // Product features modals
    const productFeaturesModals = document.querySelectorAll('.product-features-modal');
    productFeaturesModals.forEach(modal => {

        // Toggle modal open/close
        const id = modal.id;
        const modalTrigger = document.querySelector(`.product-features-modal-toggle[href="#${id}"]`);
        const modalClose = modal.querySelector('.product-features-modal-close');
        modalTrigger.addEventListener('click', () => {
            modal.classList.add('modal-is-open');
            document.body.classList.add('no-scroll');
        })
        modalClose.addEventListener('click', () => {
            modal.classList.remove('modal-is-open');
            document.body.classList.remove('no-scroll');
        })

        // Swiper with mousewheel control for horizontal scroll
        const carousel = modal.querySelector('.product-features-modal-scroll');
        const modalScroll = new Swiper(carousel, {
            modules: [FreeMode, Mousewheel, Scrollbar],
            slidesPerView: 'auto',
            freeMode: true,
            mousewheel: true,
            scrollbar: {
                el: '.product-features-modal-scrollbar',
            },
        });
    });

    // Related content carousels
    const relatedCarousel = new Swiper('.related-carousel', {
        modules: [Navigation, Scrollbar],
        slidesPerView: 'auto',
        navigation: {
            prevEl: '.related-carousel-prev',
            nextEl: '.related-carousel-next',
        },
        scrollbar: {
            el: '.related-carousel-scrollbar',
        },
    });

    // Article galleries
    const galleries = document.querySelectorAll('.article-gallery');
    galleries.forEach(gallery => {
        const galleryCarousel = gallery.querySelector('.article-gallery-carousel');
        const galleryPrev = gallery.querySelector('.article-gallery-prev');
        const galleryNext = gallery.querySelector('.article-gallery-next');
        const galleryPagination = gallery.querySelector('.article-gallery-pagination');
        const articleGallery = new Swiper(galleryCarousel, {
            modules: [EffectFade, Navigation, Pagination],
            autoHeight: true,
            speed: 600,
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            navigation: {
                nextEl: galleryNext,
                prevEl: galleryPrev,
            },
            pagination: {
                el: galleryPagination,
                type: 'fraction',
            },
        });
    });
        
    // Replace dropdown toggle text with clicked menu item on contact form dropdowns
    const contactDropdowns = document.querySelectorAll('.form-dropdown');
    contactDropdowns.forEach(dropdown => {
        const toggle = dropdown.querySelector('.dropdown-toggle-text');
        const menuItems = dropdown.querySelectorAll('.dropdown-item');
        let currentActive = dropdown.querySelector('.dropdown-item.active');

        menuItems.forEach(item => {
            item.addEventListener("click", function(e) {
                e.preventDefault();
                currentActive = dropdown.querySelector('.dropdown-item.active');
                if (currentActive) {
                    currentActive.classList.remove("active");
                }
                item.classList.add("active");
                toggle.textContent = item.textContent;
            });
        });
    });

    // Vimeo/youtube embeds
    const videoEmbeds = document.querySelectorAll('.video-embed');
    videoEmbeds.forEach(video => {
        const screen = video.querySelector(".video-embed-cover");
        const iframe = video.querySelector(".video-embed-iframe");
        const iframeUrl = iframe.src;
        screen.addEventListener('click', function() {
            iframe.src = iframeUrl + '?autoplay=1';
            screen.classList.add('video-embed-cover-hidden');
        });
    });

    // Native video upload custom play buttons
    const videoUploads = document.querySelectorAll('.video-upload');
    videoUploads.forEach(video => {
        const screen = video.querySelector(".video-upload-cover");
        const videoEl = video.querySelector("video");
        screen.addEventListener('click', function() {
            videoEl.play();
            screen.classList.add('video-embed-cover-hidden');
        });
    });

    // Enable tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

    // Packages pricing range slider
    if (document.querySelector('.packages-duration-range')) {
        const sliderInput = document.getElementById('packagesRangeInput'),
            sliderThumb = document.getElementById('packagesRangeThumb'),
            sliderLine = document.getElementById('packagesRangeLine');

        function changePackageData() {
            const packages = document.querySelectorAll('.packages-item')
            if(packages) {
                packages.forEach(item => {
                    const packageData = item.querySelector('.package-data')
                    if(packageData) {
                        const packagePlan = packageData.querySelector(`[data-price-type="${sliderInput.value}"]`)
                        const priceElement = item.querySelector('.selected-plan-price')
                        priceElement.style.opacity = 0
                        setTimeout(function() {
                            priceElement.innerHTML = packagePlan.getAttribute('data-price')
                            priceElement.style.opacity = 1
                        }, 500)
                        
                        const packageButton = item.querySelector('.package-api-id')
                        packageButton.setAttribute('href', '/pricing-plans/contact-details?planid='+packagePlan.getAttribute('data-package-id'))
                    }                    
                })
            }
        }
        

        function showSliderValue() {
            changePackageData()
            sliderThumb.innerHTML = sliderInput.value;
            sliderThumb.style.left = ((sliderInput.value - 1) * 50) + '%';
            sliderLine.style.width = ((sliderInput.value - 1) * 50) + '%';
        }

        showSliderValue();
        window.addEventListener("resize",showSliderValue);
        sliderInput.addEventListener('input', showSliderValue, false);
    }

    // Set active classes on in page navigation links
    if (document.querySelector('.page-nav-target')) {
        const pageNavTargets = document.querySelectorAll('.page-nav-target');
        let topPos = 0;
        let topPosArray = [];

        const getActiveLink = () => {
            pageNavTargets.forEach((target,index) => {
                const targetId = target.id;
                const targetLink = document.querySelector(`.page-nav-link[href="#${targetId}"]`);
                topPos = Math.round(target.getBoundingClientRect().top);
                if (topPos < 0) {
                    topPos = topPos * -1;
                }
                topPosArray[index] = topPos;
                let indexOfClosest = topPosArray.indexOf(Math.min(...topPosArray));

                if (index) {
                    if (index !== indexOfClosest && targetLink.classList.contains('active')) {
                        targetLink.classList.remove('active');
                    } else if (index === indexOfClosest) {
                        targetLink.classList.add('active');
                    }
                }
            })
        }

        getActiveLink();
        window.addEventListener('scroll', getActiveLink);
    }

    // Two menus on page
    if (document.querySelector('.page-nav-target-secondary')) {
        const pageNavTargets = document.querySelectorAll('.page-nav-target-secondary');
        let topPos = 0;
        let topPosArray = [];

        const getActiveLink = () => {
            pageNavTargets.forEach((target,index) => {
                const targetId = target.id;
                const targetLink = document.querySelector(`.page-nav-link-secondary[href="#${targetId}"]`);
                topPos = Math.round(target.getBoundingClientRect().top);
                if (topPos < 0) {
                    topPos = topPos * -1;
                }
                topPosArray[index] = topPos;
                let indexOfClosest = topPosArray.indexOf(Math.min(...topPosArray));

                if (index !== indexOfClosest && targetLink.classList.contains('active')) {
                    targetLink.classList.remove('active');
                } else if (index === indexOfClosest) {
                    targetLink.classList.add('active');
                }
            })
        }

        getActiveLink();
        window.addEventListener('scroll', getActiveLink);
    }

    // Pricing table match heights
    function matchPricingHeights() {
        const pricingItems = Array.from(document.querySelectorAll('.packages-item-main'))
        pricingItems.forEach(item => item.removeAttribute('style'))
        let tallestItem = Math.max(...pricingItems.map(item => item.getBoundingClientRect().height))
        pricingItems.forEach(item => item.style.height = tallestItem + 'px' )
    }
    matchPricingHeights()
    window.onresize = (matchPricingHeights)

    // Mixitup filtering
    if (document.querySelector('.mix-container')) {
        const filterMixer = mixitup('.mix-container', {
            animation: {
                effects: 'fade translateY(25%)',
                duration: 500,
                easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)'
            },
        });
    }

    const faqNavItems = document.querySelectorAll('.faq-item');

    faqNavItems.forEach(item => {
        item.addEventListener('click', function(event) {
            event.preventDefault()
            
            const targetId = event.target.getAttribute('data-target')
            const targetElement = document.querySelector(targetId)
            const elementPosition = Math.round(targetElement.getBoundingClientRect().top)
            const headerOffset = elementPosition > 0 ? 20 : 100
            const offsetPostion = elementPosition + window.scrollY - headerOffset 
            
            window.scrollTo({
                top: offsetPostion,
                behavior: 'smooth'
            })
        })
    });

};

const afterLoadScripts = () => {

    // Homepage loader
    const homeLoaderEl = document.querySelector('.home-loader');
    const storage = JSON.parse(sessionStorage.getItem('zeppit_storage_loader')) ? JSON.parse(sessionStorage.getItem('zeppit_storage_loader')) : {};
    const showLoader = false //(storage && storage['hideLoader']) ? false : true;
    const revealFadeIns = document.querySelector('.reveal-fade-in');

    const hashUrl = window.location.hash;
    if (revealFadeIns && !hashUrl) {
        gsap.set('.reveal-fade-in', {
            opacity: 0,
            y: 40,
        });
    }

    if (homeLoaderEl && revealFadeIns) {
        let timeOut1 = 400;
        storage.hideLoader = true;
        sessionStorage.setItem('zeppit_storage_loader', JSON.stringify(storage));
        if(!showLoader) {
            homeLoaderEl.remove();  
        } else {
            homeLoaderEl.classList.remove('d-none')
            const animationData = require('./data.json')
            var logoAnimation = bodymovin.loadAnimation({
                container: document.getElementById('homeLoader'),
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData
            });
            setTimeout(() => { logoAnimation.play(); }, 500);
            timeOut1 = 5000;
        } 

        setTimeout(() => {
            homeLoaderEl.classList.add('page-loaded');
            ScrollTrigger.batch('.reveal-fade-in', {
                start: "top 90%",
                onEnter: reveals => {
                    gsap.to(reveals, {
                        opacity: 1,
                        y: 0,
                        stagger: 0.15,
                        duration: 0.4,
                    });
                },
                once: true
            });
        }, timeOut1);
    } else if (revealFadeIns && !hashUrl) {
        ScrollTrigger.batch('.reveal-fade-in', {
            start: "top 90%",
            onEnter: reveals => {
                gsap.to(reveals, {
                    opacity: 1,
                    y: 0,
                    stagger: 0.15,
                    duration: 0.4,
                });
            },
            once: true
        });
    }
}

// Page transitions
const pageTransitions = () => {
    // Get all internal transition links
    let transitionTriggers = document.querySelectorAll('a:not([target="_blank"]):not([href^="#"]):not([href^="mailto:"]):not([href^="tel:"]):not(.venobox):not(.disable-transition)');

    // Transition element
    const transitionEl = document.querySelector(".page-transition");
    
    // Fade out transition
    const transitionOut = () => {
        
        return gsap.to(transitionEl, {
            autoAlpha: 0,
            duration: 0.5,
        });
    };
    
    // Fade in transition
    const transitionIn = () => {
        
        return gsap.to(transitionEl, {
            autoAlpha: 1,
            duration: 0.5,
        });
    };

    // Transition when page loaded
    window.onpageshow = () => {
        ScrollTrigger.refresh();
        transitionIn();
    }
    
    // Transition when internal link clicked
    transitionTriggers.forEach(trigger => {
        const url = trigger.href;
        const clearUrl = trigger.href.split('#')[0];
        trigger.addEventListener('click', (e) => {
            let tUrl = location.href.split('#')[0];
            if (tUrl !== clearUrl) {
                e.preventDefault();
                transitionOut();
                setTimeout(() => {
                    location.assign(url);
                }, 500);
            }
            else if(url !== location.href) {
                e.preventDefault();
                setTimeout(() => {
                    location.assign(url);
                }, 100);
            } else {
                e.preventDefault();
            }
        })
    })
}

// Run scripts once when page loads
document.addEventListener("DOMContentLoaded",function() {
    document.body.classList.remove('preload');
    runScripts();
    afterLoadScripts();
    pageTransitions();
})

// Run scripts only when everything on page has loaded
window.addEventListener("load",function() {
    // Initialise marquees
    if (document.querySelector('.marquee3k')) {
        Marquee3k.init()
    }
})

export function updateTooltipTitleById (id, title) {
    const loginButtonTooltip = document.getElementById(id)
    const tooltip = Tooltip.getOrCreateInstance(loginButtonTooltip)
    tooltip.setContent({'.tooltip-inner': title})
}
